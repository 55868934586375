<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-10">Histórico</h1>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 md:col-span-4 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Data Inicial</label>
        <datepicker @closed="start" v-model="dataInicial" name="dataInicial" id="dataInicial" :language="ptBR" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-4 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Data Final</label>
        <datepicker @closed="start" v-model="dataFinal" name="dataFinal" id="dataFinal" :language="ptBR" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">{{ total}} ações registradas </h4>
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nome
                  </th>
                 

                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in list" :key="item._id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.data | moment("DD/MM/YYYY HH:mm:ss")}}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.texto ? traduz(item.texto) : ''}}

                        {{getBody(item.body)}}
                      </div>
                    </div>
                  </td>

                  
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            ptBR: ptBR,
            route: 'historico',
            dataInicial: new Date(moment().startOf('month')),
            dataFinal: new Date(moment().endOf('day')),
            list: [],
            page: 1,
            perPage: 10,
            total: 0,
        }
    },
    methods: {
        async start() {
          this.page = 1;

          const id = this.$route.params.id;
          const skip = (this.page - 1) * this.perPage;
          const limit = this.perPage;

          const listReq = await this.$http.post(`/v1/users/historico/${id}/list`, { 
            busca: this.busca, 
            dataInicial: this.dataInicial, 
            dataFinal: this.dataFinal, 
            limit, 
            skip,
          });

          this.list = listReq.data.data;
          this.total = listReq.data.total;
        },
        async setPage(page) {
          this.page = page;

          const id = this.$route.params.id;
          const skip = (page - 1) * this.perPage;
          const limit = this.perPage;

          const listReq = await this.$http.post(`/v1/users/historico/${id}/list`, { 
            busca: this.busca, 
            dataInicial: this.dataInicial, 
            dataFinal: this.dataFinal, 
            limit, 
            skip,
          });

          this.list = listReq.data.data;
          this.total = listReq.data.total;
        },
        traduz(text) {
          text = text.replace("PUT", "Editou");
          text = text.replace("POST", "Salvou");
          return text;
        },
        getBody(body) {
          const bodyfinal = JSON.parse(body);

          if (bodyfinal.nome && bodyfinal.quantidade)
            return `${bodyfinal.nome} - ${bodyfinal.quantidade} m³`;

          if (bodyfinal.nome && !bodyfinal.quantidade)
            return `${bodyfinal.nome}`;

          if (bodyfinal.tipo)
            return `${bodyfinal.tipo} - ${bodyfinal.leitura}`;
        }
    },
    async beforeMount() {
        this.start();
    },
}
</script>